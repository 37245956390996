<template lang="pug">
  div
    v-row
      v-col(lg="6" cols="12")
        v-text-field(
          label="Invoice No."
          :value="invoiceNo || 'N/A'"
          :error-messages="errors.id || []"
          disabled
        )
      v-col(lg="6" cols="12")
        v-text-field(
          label="Invoice Date"
          type="date"
          :value="invoiceDate"
          :error-messages="errors.invoice_date || []"

        )
      v-col(lg="6" cols="12")
        v-text-field(
          label="Due Date"
          type="date"
          v-model="dueDate"
          :error-messages="errors.due_date || []"
        )
      //- v-col(lg="6" cols="12")
      //-   v-text-field(
      //-     label="Tax"
      //-     v-model="tax"
      //-   )
      //- v-col(lg="6" cols="12")
      //-   v-text-field(
      //-     label="Shipping"
      //-     v-model="shipping"
      //-   )
      v-col(lg="6" cols="12")
        v-text-field(
          label="Amount Due"
          :value="amountDue.phpFormat()"
          :error-messages="errors.amount_due || []"
          readonly
        )
</template>
<script>

const currentDate = () => new Date().toLocaleString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' })

const inputVars = attributes => ({
  id: null,
  invoiceDate: currentDate(),
  dueDate: currentDate(),
  tax: null,
  shipping: null,
  amountDue: null,
  ...attributes,
})

export default {
  name: 'Invoice',
  props: {
    inputFields: {
      type: Object,
      default: inputVars,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  created () {
    this.updateInputFields()
  },
  data: () => ({
    ...inputVars(),
  }),
  computed: {
    invoiceNo () {
      const { id } = this
      if (!id) return null
      return `DR${id.toString().padStart(6, '0')}`
    },
  },
  watch: {
    inputFields: {
      handler (val) {
        this.updateFieldValues()
      },
      deep: true,
    },
    id () {
      this.updateInputFields()
    },
    invoiceDate () {
      this.updateInputFields()
    },
    dueDate () {
      this.updateInputFields()
    },
    tax () {
      this.updateInputFields()
    },
    shipping () {
      this.updateInputFields()
    },
  },
  methods: {
    updateFieldValues () {
      const result = this.inputFields
      Object.keys(result).forEach(key => {
        if (!result[key]) return
        this[key] = result[key]
      })
    },
    updateInputFields () {
      const fieldValues = this.getFieldValues()
      this.$emit('update:inputFields', fieldValues)
    },
    getFieldValues () {
      const result = inputVars()
      Object.keys(result).forEach(key => {
        result[key] = this[key]
      })
      return result
    },
  },
}
</script>